import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const BuySwiper = ({ categories, category, setCategory, setLimit }) => {
  return (
    <>
      <Swiper className="mySwiper" slidesPerView={'auto'} spaceBetween={24}>
        {categories.map(item => (
          <SwiperSlide key={item.id}>
            <button
              className={` buy__button
                         ${item.value === category.value ? 'buy__button_active' : ''}`}
              onClick={() => {
                setCategory({ value: item.value, id: item.id });
                setLimit(10);
              }}>
              {item.value}
            </button>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="buy__tags">
        {categories.map((item, idx) => (
          <button
            key={idx}
            className={` buy__button
                         ${item.value === category.value ? 'buy__button_active' : ''}`}
            onClick={() => {
              setCategory({ value: item.value, id: item.id });
              setLimit(10);
            }}>
            {item.value}
          </button>
        ))}
      </div>
    </>
  );
};

export default BuySwiper;
