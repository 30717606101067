import React from 'react';
import { useTranslation } from 'react-i18next';
import Money from '../../../assets/home/money.svg';
import Calendar from '../../../assets/home/calendary.svg';
import Clock from '../../../assets/home/clock.svg';
// import File from '../../../assets/home/file.svg';
// import Phone from '../../../assets/home/phone.svg';
// import People from '../../../assets/home/people.svg';

import TermsCard from './TermsCard';
// import Title from '../Title/Title';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <section className="terms" id="condition">
      <div className="container">
        <h5 className="terms__title">{t('terms.block1.main')}</h5>
        <div className="terms__row">
          <TermsCard image={Money} text={t('terms.block1.card1')} />
          <TermsCard image={Calendar} text={t('terms.block1.card2')} />
          <TermsCard image={Clock} text={t('terms.block1.card3')} />
        </div>
        <h5 style={{ marginTop: '40px' }} className="terms__title">
          {t('terms.block2.main')}
        </h5>
        <div className="terms__row">
          <TermsCard image={Money} text={t('terms.block2.card1')} />
          <TermsCard image={Calendar} text={t('terms.block2.card2')} />
          <TermsCard image={Clock} text={t('terms.block2.card3')} />
        </div>
      </div>
    </section>
  );
};

export default Terms;
