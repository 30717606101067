import React from 'react';
import DownloadButton from '../DownloadButton/DownloadButton';
import { useTranslation } from 'react-i18next';
import Image from '../../../assets/home/image.png';
import ImageSmall from '../../../assets/home/imageSmall.png';
import ImageSmall2 from '../../../assets/home/imageSmall2.png';

const FirstScreenHome = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="firstScreen">
      <div className="container">
        <div className="firstScreen__row">
          <div
            style={{ margin: i18n.language === 'ru' ? `179px 0 184px` : `90px 0 184px` }}
            className="firstScreen__left">
            <h2 className="firstScreen__title" dangerouslySetInnerHTML={{ __html: t('block1.title') }} />
            <p className="firstScreen__subtitle" dangerouslySetInnerHTML={{ __html: t('block1.subtitle') }} />
            <DownloadButton text={t('block1.button')} />
          </div>
          <div className="firstScreen__circle"></div>
          <picture>
            <source srcSet={ImageSmall2} media="(max-width: 500px)" />
            <source srcSet={ImageSmall} media="(max-width: 992px)" />
            <img className="firstScreen__img" src={Image} alt="cash2u women" />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default FirstScreenHome;
