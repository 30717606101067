import React, { useState } from 'react';
import axios from 'axios';

const FormBlock = () => {
  const AnswerBy = {
    Phone: 'Phone',
    Email: 'Email',
    Mail: 'Mail',
  };

  const AppealType = {
    Offer: 'Offer',
    Complaint: 'Complaint',
    Gratitude: 'Gratitude',
  };

  const CustomerType = {
    IndividualCustomer: 'IndividualCustomer',
    LegalCustomer: 'LegalCustomer',
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    customerType: CustomerType.IndividualCustomer,
    appealType: AppealType.Offer,
    fullName: '',
    answersPhoneNumber: '',
    answersEmail: '',
    currentResidence: '',
    getAnswerBy: AnswerBy.Email,
    content: '',
    email: '',
    mailingAddress: '',
    phoneNumber: '',
  });

  const clearFormData = () => {
    setFormData({
      customerType: CustomerType.IndividualCustomer,
      appealType: AppealType.Offer,
      fullName: '',
      answersPhoneNumber: '',
      answersEmail: '',
      currentResidence: '',
      getAnswerBy: AnswerBy.Email,
      content: '',
      email: '',
      mailingAddress: '',
      phoneNumber: '',
    });
  };

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? value : '') : value;

    if (name === 'getAnswerBy' && checked) {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
        email: value === AnswerBy.Email ? '' : formData.email,
        mailingAddress: value === AnswerBy.Mail ? '' : formData.mailingAddress,
        phoneNumber: value === AnswerBy.Phone ? '' : formData.phoneNumber,
      }));
    } else {
      const updatedFormData = { ...formData, [name]: newValue };
      Object.keys(updatedFormData).forEach(key => {
        if (updatedFormData[key] === '') {
          delete updatedFormData[key];
        }
      });
      setFormData(updatedFormData);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_COMPLAINT_FORM}/guest/complaint`, formData);
      setSuccessMessage('Спасибо за Ваше обращение');

      clearFormData();

      // Запуск таймера для скрытия сообщения об успехе через 5 секунд
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (error) {
      console.error('Ошибка при отправке данных на сервер:', error);
    }
  };

  return (
    <section className="formBlock">
      <div className="container">
        <h2 className="formBlock__title">Жалобы и предложения</h2>
        <p className="formBlock__subtitle">
          У Вас есть предложение, жалоба или благодарность? Заполните форму и отправьте ее нам
        </p>
        <form className="formBlock__form" method="POST" onSubmit={handleSubmit}>
          <div className="first">
            <div className="col-6 space-between">
              <fieldset className="formBlock__form__radio customerType">
                <h3>Тип лица*</h3>
                <input
                  type="radio"
                  name="customerType"
                  id={CustomerType.IndividualCustomer}
                  value={CustomerType.IndividualCustomer}
                  checked={formData.customerType === CustomerType.IndividualCustomer}
                  onChange={handleChange}
                  required
                />
                <label htmlFor={CustomerType.IndividualCustomer}>Физическое лицо</label>
                <input
                  type="radio"
                  name="customerType"
                  id={CustomerType.LegalCustomer}
                  value={CustomerType.LegalCustomer}
                  checked={formData.customerType === CustomerType.LegalCustomer}
                  onChange={handleChange}
                  required
                />
                <label htmlFor={CustomerType.LegalCustomer}>Юридическое лицо</label>
              </fieldset>
              <div className=" formBlock__form__radio appealType">
                <h2>Выберите тип обращения*</h2>
                <div>
                  <input
                    type="radio"
                    name="appealType"
                    id={AppealType.Complaint}
                    value={AppealType.Complaint}
                    checked={formData.appealType === AppealType.Complaint}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor={AppealType.Complaint}>Жалоба</label>
                  <input
                    type="radio"
                    name="appealType"
                    id={AppealType.Offer}
                    value={AppealType.Offer}
                    checked={formData.appealType === AppealType.Offer}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor={AppealType.Offer}>Предложение</label>
                  <input
                    type="radio"
                    name="appealType"
                    id={AppealType.Gratitude}
                    value={AppealType.Gratitude}
                    checked={formData.appealType === AppealType.Gratitude}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor={AppealType.Gratitude}>Благодарность</label>
                </div>
              </div>
            </div>
            <textarea
              className="col-6"
              placeholder="Отправьте свое сообщение"
              cols="49"
              rows="4"
              name="content"
              value={formData.content}
              onChange={handleChange}
              required
              minLength="20"
              maxLength="10000"></textarea>
          </div>
          <div className="first">
            <div className="col-6">
              <div className="formBlock__form__input ">
                <input
                  type="text"
                  className="formBlock__input"
                  placeholder="Фамилия Имя Отчество"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="256"
                  pattern="[A-Za-zА-Яа-яЁё]+\s[A-Za-zА-Яа-яЁё]+"
                  title="Введите ФИО в формате: Фамилия Имя Отчество(если есть)"
                />
                <input
                  type="tel"
                  className="formBlock__input"
                  placeholder="Номер телефона"
                  name="answersPhoneNumber"
                  value={formData.answersPhoneNumber}
                  onChange={handleChange}
                  required
                  minLength="12"
                  maxLength="12"
                  pattern="996[0-9]{9}"
                  title="Введите номер в формате: 996 555 00 00 00"
                />
                <input
                  type="email"
                  className="formBlock__input"
                  placeholder="Адрес электронной почты"
                  title="Введите email в формате: mail@example.com"
                  pattern=".+@.+\..+"
                  name="answersEmail"
                  value={formData.answersEmail}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="100"
                />
                <input
                  type="text"
                  className="formBlock__input"
                  placeholder="Текущий адрес проживания"
                  name="currentResidence"
                  value={formData.currentResidence}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="256"
                />
              </div>
            </div>

            <div className="col-6">
              <p>* Рассморению не подлежат обращения:</p>
              <p>&emsp;- не поддающиеся прочтению</p>
              <p>&emsp;- содержащие ненормативную лексику и</p>
              <p>&emsp;&nbsp;&nbsp;&nbsp;оскорбительные высказывания</p>
              <p>&emsp;- ранее рассмотренные</p>
              <p>&emsp;- анонимные</p>
              <p>&emsp;- не относящиеся к компетенции ОсОО "МКК Келечек"</p>
              <br />
              <p>** Пожалуйста, указывайте подробную информацию,</p>
              <p>необходимую для идентификации и более детального</p>
              <p>понимании ситуации. Это позволит нам предоставить</p>
              <p>наиболее полный ответ на Ваше обращение.</p>
            </div>
          </div>

          <div className="first">
            <div className="col-5 formBlock__form__checkbox customerType first second">
              <input
                type="radio"
                id={AnswerBy.Email}
                name="getAnswerBy"
                value={AnswerBy.Email}
                checked={formData.getAnswerBy === AnswerBy.Email}
                onChange={handleChange}
                required
              />
              <label htmlFor={AnswerBy.Email}>По электронной почте (ввести адрес):</label>
              <input
                type="radio"
                id={AnswerBy.Mail}
                name="getAnswerBy"
                value={AnswerBy.Mail}
                checked={formData.getAnswerBy === AnswerBy.Mail}
                onChange={handleChange}
                required
              />
              <label htmlFor={AnswerBy.Mail}>По почте (ввести почтовый адрес)</label>
              <input
                type="radio"
                id={AnswerBy.Phone}
                name="getAnswerBy"
                value={AnswerBy.Phone}
                checked={formData.getAnswerBy === AnswerBy.Phone}
                onChange={handleChange}
                required
              />
              <label htmlFor={AnswerBy.Phone}>По телефону (ввести номер телефона)</label>
            </div>
            <div className="col-7">
              {formData.getAnswerBy === AnswerBy.Email && (
                <input
                  type="email"
                  className="formBlock__input"
                  placeholder="Введите адрес электронной почты"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="100"
                  pattern=".+@.+\..+"
                  title="Введите email в формате: mail@example.com"
                />
              )}
              {formData.getAnswerBy === AnswerBy.Mail && (
                <input
                  type="text"
                  className="formBlock__input"
                  placeholder="Введите текущий адрес проживания"
                  name="mailingAddress"
                  value={formData.mailingAddress}
                  onChange={handleChange}
                  required
                  minLength="5"
                  maxLength="256"
                />
              )}
              {formData.getAnswerBy === AnswerBy.Phone && (
                <input
                  type="tel"
                  className="formBlock__input"
                  placeholder="Введите контактный телефон"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  minLength="12"
                  maxLength="12"
                  pattern="996[0-9]{9}"
                  title="Введите номер в формате: 996 555 00 00 00"
                />
              )}
            </div>
          </div>

          <button className="formBlock__btn" type="submit">
            Отправить
          </button>
        </form>

        {successMessage && <p className="text-success">{successMessage}</p>}
      </div>
    </section>
  );
};

export default FormBlock;
