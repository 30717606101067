import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Archive = () => {
  const { i18n } = useTranslation();

  return (
    <section className="docs">
      <div className="container">
        <h2 className="docs__title">Архивы</h2>
        <ul className="docs__menu">
          <li className="docs__item">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="file-text"
              width="40"
              height="40"
              fill="currentColor"
              aria-hidden="true">
              <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
            </svg>
            <div>
              <Link to="/archive/loan-agreement-19.09.2023" className="docs__link">
                {i18n.language !== 'ky' ? (
                  <p>Публичный кредитный договор - редакция от 19.09.2023</p>
                ) : (
                  <p>Мамлекеттик насыя келишими - чыгарылыш 19.09.2023</p>
                )}
              </Link>
            </div>
          </li>
          <li className="docs__item">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="file-text"
              width="40"
              height="40"
              fill="currentColor"
              aria-hidden="true">
              <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
            </svg>
            <div>
              <Link to="/archive/loan-agreement-20.08.2023" className="docs__link">
                {i18n.language !== 'ky' ? (
                  <p>Публичный кредитный договор - редакция от 20.08.2023</p>
                ) : (
                  <p>Мамлекеттик насыя келишими - чыгарылыш 20.08.2023</p>
                )}
              </Link>
            </div>
          </li>
          <li className="docs__item">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="file-text"
              width="40"
              height="40"
              fill="currentColor"
              aria-hidden="true">
              <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
            </svg>
            <div>
              <Link to="/archive/loan-agreement-01.06.2022" className="docs__link">
                {i18n.language !== 'ky'
                  ? 'Публичный кредитный договор - редакция от 01.06.2022'
                  : 'Мамлекеттик насыя келишими - чыгарылыш 01.06.2022'}
              </Link>
              <br />
            </div>
          </li>
          <li className="docs__item">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="file-text"
              width="40"
              height="40"
              fill="currentColor"
              aria-hidden="true">
              <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
            </svg>
            <Link to="/archive/rights-and-expenses" className="docs__link">
              {i18n.language !== 'ky'
                ? 'Перечень прав, расходов (платежей) и штрафных санкций'
                : 'Укуктардын, чыгымдардын (төлөмдөрдүн) жана айып санкцияларынын тизмеги'}
            </Link>
          </li>
          <li className="docs__item">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="file-text"
              width="40"
              height="40"
              fill="currentColor"
              aria-hidden="true">
              <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
            </svg>
            <div>
              <Link to="/archive/loan-agreement" className="docs__link">
                {i18n.language !== 'ky' ? 'Публичный кредитный договор' : 'Мамлекеттик насыя келишими'}
              </Link>
              <br />
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Archive;
