import React, { useState } from 'react';

const MobileQuestionAccordion = ({ title, info }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mobileQuestions__accordion" onClick={() => setIsOpen(!isOpen)}>
      <div className="mobileQuestions__accordion-top">
        <h3 className="mobileQuestions__accordion-title">{title}</h3>
        <div className="mobileQuestions__accordion-icon">
          {isOpen ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.4122 7.41371L16.998 5.9995L11.999 10.9985L6.68628 5.68579L5.27207 7.10001L10.5848 12.4127L5.68433 17.3132L7.09854 18.7274L11.999 13.827L16.5858 18.4137L18 16.9995L13.4132 12.4127L18.4122 7.41371Z"
                fill="#21222B"
              />
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 4H11V11H4V13H11V20H13V13H20V11H13V4Z" fill="#A2B4C3" />
            </svg>
          )}
        </div>
      </div>
      <p
        className={`mobileQuestions__accordion-info ${isOpen ? 'mobileQuestions__accordion-info_active' : ''}`}
        dangerouslySetInnerHTML={{ __html: info }}
      />
    </div>
  );
};

export default MobileQuestionAccordion;
