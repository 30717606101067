import React, { useState } from 'react';
import SwitchLang from './switchLang/SwitchLang';
import Logo from '../../assets/logo/cash2u.svg';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import LinkItem from './LinkItem';

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [isBurger, setIsBurger] = useState(false);

  return (
    <header className="header">
      <div className="container">
        <nav className="header__nav">
          <div className="header__left">
            <h1 className="header__title">
              <Link to="/" className="header__title-link">
                <img className="header__logo" src={Logo} alt="Cash2u" />
              </Link>
            </h1>
          </div>

          <div className={`header__right ${isBurger ? 'header__right_active' : ''}`}>
            <ul className="header__menu">
              {pathname === '/business' ? (
                <>
                  <li className="header__item">
                    <a href="#trust" className="header__link">
                      {t('partner-page.header.link1')}
                    </a>
                  </li>
                  <li className="header__item">
                    <a href="#work" className="header__link">
                      {t('partner-page.header.link2')}
                    </a>
                  </li>
                  <li className="header__item">
                    <a href="#help" className="header__link">
                      {t('partner-page.header.link3')}
                    </a>
                  </li>
                  <li className="header__item">
                    <a href="#profit" className="header__link">
                      {t('partner-page.header.link4')}
                    </a>
                  </li>
                  <li className="header__item">
                    <a href="#request" className="header__link">
                      {t('partner-page.header.link5')}
                    </a>
                  </li>
                </>
              ) : (
                <>
                  {pathname === '/' ? (
                    <li className="header__item">
                      <a href="#condition" className="header__link">
                        {t('header-client.link1')}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {pathname === '/' ? (
                    <li className="header__item">
                      <a href="#buy" className="header__link">
                        {t('header-client.link2')}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {pathname === '/' ? (
                    <li className="header__item">
                      <a href="#issue" className="header__link">
                        {t('header-client.link3')}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {pathname === '/' ? (
                    <li className="header__item">
                      <a href="#about" className="header__link">
                        {t('header-client.link4')}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {pathname === '/' ? (
                    <li className="header__item">
                      <a href="#repay" className="header__link">
                        {t('header-client.link5')}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {pathname === '/' ? (
                    <li className="header__item">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a className="header__link">
                        <Link to="/career">{t('footer-client.column1.line4')}</Link>
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  <LinkItem path="/business" text={t('header-client.link6')} />
                </>
              )}
            </ul>
            <SwitchLang />
          </div>

          <div className={`burger ${isBurger ? 'burger__active' : ''}`} onClick={() => setIsBurger(!isBurger)}>
            <span className="burger__line" />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
