import React from 'react';
import Som from '../Som/Som';

const PriceCard = ({ price, bg, color }) => {
  return (
    <div style={{ background: bg }} className="pay__card pay__card-price">
      <p className="pay__card-money">
        {price} <Som />
      </p>
    </div>
  );
};

export default PriceCard;
