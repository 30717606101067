import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import GoogleSvg from '../../assets/social-medias/google.svg';
import AppStoreSvg from '../../assets/social-medias/appStore.svg';
import InstagramSvg from '../../assets/social-medias/instagram.svg';
import TelegramSvg from '../../assets/social-medias/telegram.svg';
import FBSvg from '../../assets/social-medias/fb.svg';
import OkSvg from '../../assets/social-medias/ok.svg';
import VkSvg from '../../assets/social-medias/vk.svg';
import YouTubeSvg from '../../assets/social-medias/youtube.svg';
import TiktokSvg from '../../assets/social-medias/tiktok.svg';
import WpSvg from '../../assets/social-medias/wp.svg';
import useGAEventTracker from '../../analytics/useGAEventTracker';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const GAEventsTracker = useGAEventTracker('External links');

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__row">
          <div className="footer__col">
            <h3 className="footer__title">{t('footer-client.column1.title')}</h3>
            <ul className="footer__list">
              {pathname === '/business' ? (
                <>
                  <li className="footer__item">
                    <a href="#work">{t('footer-partner.column1.line1')}</a>
                  </li>
                </>
              ) : (
                <>
                  <li className="footer__item">
                    <a href="#about">{t('footer-client.column1.line1')}</a>
                  </li>
                  <li className="footer__item">
                    <a href="#issue">{t('footer-client.column1.line2')}</a>
                  </li>
                  <li className="footer__item">
                    <Link to="/docs">{t('footer-client.column1.line3')}</Link>
                  </li>
                  <li className="footer__item">
                    <Link to="/career">{t('footer-client.column1.line4')}</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="footer__col">
            <h3 className="footer__title">cash2u</h3>
            <ul className="footer__list">
              {pathname === '/business' ? (
                <>
                  <li className="footer__item">
                    <a href="#profit">{t('footer-partner.column2.line1')}</a>
                  </li>
                  <li className="footer__item">
                    <a href="#request">{t('footer-partner.column2.line2')}</a>
                  </li>
                </>
              ) : (
                <>
                  <li className="footer__item">
                    <a href="#questions">{t('footer-client.column2.line1')}</a>
                  </li>
                  <li className="footer__item">
                    <a href="#repay">{t('footer-client.column2.line2')}</a>
                  </li>
                  <li className="footer__item">
                    <Link to="/all-news">{t('footer-client.column2.line3')}</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="footer__col">
            <h3 className="footer__title">Контакты</h3>
            <ul className="footer__list">
              <li className="footer__item">
                <Link to="/">{t('footer-client.column3.calls')}</Link>
              </li>
            </ul>
            <div className="footer__phones">
              <a
                className="footer__phone"
                onClick={() => GAEventsTracker('Звонок', '0772 55 33 33')}
                href="tel:0772 55 33 33">
                0772 55 33 33
              </a>
              <a
                className="footer__phone"
                onClick={() => GAEventsTracker('Звонок', '0552 55 33 33')}
                href="tel:0552 55 33 33">
                0552 55 33 33
              </a>
              <a
                className="footer__phone"
                onClick={() => GAEventsTracker('Звонок', '0502 55 33 33')}
                href="tel:0502 55 33 33">
                0502 55 33 33
              </a>
            </div>
          </div>
          <div className="footer__col">
            <div className="footer__download">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://bit.ly/3sDPx0t"
                onClick={() => GAEventsTracker('PlayMarket', 'https://bit.ly/3sDPx0t')}>
                <LazyLoadImage effect="blur" className="footer__download-img" src={GoogleSvg} alt="PlayMarket" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://apple.co/3gOrOoO"
                onClick={() => GAEventsTracker('AppStore', 'https://apple.co/3gOrOoO')}>
                <LazyLoadImage effect="blur" className="footer__download-img" src={AppStoreSvg} alt="AppStore" />
              </a>
            </div>
            <div className="footer__socials">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/Cash2u-102189698862906/"
                className="footer__link"
                onClick={() => GAEventsTracker('Facebook', 'https://www.facebook.com/Cash2u-102189698862906/')}>
                <LazyLoadImage effect="blur" src={FBSvg} alt="facebook" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => GAEventsTracker('Telegram', 'tg://resolve?domain=@KelechekMKKbot')}
                href="tg://resolve?domain=@KelechekMKKbot"
                className="footer__link">
                <LazyLoadImage effect="blur" src={TelegramSvg} alt="telegram" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => GAEventsTracker('Whats Up', 'https://wa.me/+996552553333')}
                href="https://wa.me/+996552553333"
                className="footer__link">
                <LazyLoadImage effect="blur" src={WpSvg} alt="Whats Up" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => GAEventsTracker('Instagram', 'https://www.instagram.com/cash2u.kg')}
                href="https://www.instagram.com/cash2u.kg"
                className="footer__link">
                <LazyLoadImage effect="blur" src={InstagramSvg} alt="instagram" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => GAEventsTracker('Tiktok', 'https://www.tiktok.com/@cash2u.kg')}
                href="https://www.tiktok.com/@cash2u.kg"
                className="footer__link">
                <LazyLoadImage effect="blur" src={TiktokSvg} alt="tiktok" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => GAEventsTracker('VK', 'https://vk.com/cash2u')}
                href="https://vk.com/cash2u"
                className="footer__link">
                <LazyLoadImage effect="blur" src={VkSvg} alt="vk" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => GAEventsTracker('YouTube', 'https://www.youtube.com/channel/UCYd4ljGJODrg1mCLNLl85aA')}
                href="https://www.youtube.com/channel/UCYd4ljGJODrg1mCLNLl85aA"
                className="footer__link">
                <LazyLoadImage effect="blur" src={YouTubeSvg} alt="youtube" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => GAEventsTracker('Ok', 'https://ok.ru/profile/582735885535')}
                href="https://ok.ru/profile/582735885535"
                className="footer__link">
                <LazyLoadImage effect="blur" src={OkSvg} alt="ok" />
              </a>
            </div>
          </div>
        </div>
        <p className="footer__text">{t('footer-client.column3.bottomText')}</p>
      </div>
    </footer>
  );
};

export default Footer;
