import React from 'react';
import { Link } from 'react-router-dom';

const LinkItem = ({ path, text }) => {
  return (
    <li className="header__item">
      <Link to={path} className="header__link">
        {text}
      </Link>
    </li>
  );
};

export default LinkItem;
