import React from 'react';
import Title from '../Title/Title';
import { useTranslation } from 'react-i18next';
// import {Link} from "react-router-dom";
import QRcode from './QRCode.png';
import Apple from './appStoreBig.svg';
import Market from './google-play-badge.svg';

import Video from './video.mp4';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import useGAEventTracker from '../../../analytics/useGAEventTracker';

const AboutApp = () => {
  const { t } = useTranslation();
  const GAEventsTracker = useGAEventTracker('About App');

  return (
    <section className="aboutApp" id="about">
      <div className="container">
        <div className="aboutApp__content">
          <div className="aboutApp__left">
            <Title text={t('block8.title')} count="40px" />
            <h3 className="aboutApp__subtitle">{t('block8.subtitle')}</h3>

            <ul className="aboutApp__list">
              <li className="aboutApp__item">{t('block8.l1')}</li>
              <li className="aboutApp__item">{t('block8.l2')}</li>
              <li className="aboutApp__item">{t('block8.l3')}</li>
            </ul>
            <div className="aboutApp__links">
              <div className="aboutApp__qrcode">
                <h4 className="aboutApp__qrcode-title" dangerouslySetInnerHTML={{ __html: t('block8.qrcode') }} />
                <LazyLoadImage effect="blur" className="aboutApp__qrcode-img" src={QRcode} alt="QRcode" />
              </div>
              <div className="aboutApp__apps">
                <a
                  onClick={() => GAEventsTracker('download', 'App store')}
                  href="https://apple.co/3gOrOoO"
                  target="_blank"
                  rel="noopener noreferrer">
                  <LazyLoadImage effect="blur" className="aboutApp__apps-img" src={Apple} alt="App Store" />
                </a>
                <a
                  onClick={() => GAEventsTracker('download', 'Play market')}
                  href="https://bit.ly/3sDPx0t"
                  target="_blank"
                  rel="noopener noreferrer">
                  <LazyLoadImage effect="blur" className="aboutApp__apps-img" src={Market} alt="Play market" />
                </a>
              </div>
            </div>
          </div>
          <div className="aboutApp__right">
            <video
              className="aboutApp__video"
              playsInline
              controls
              src={Video}
              onPlay={() => GAEventsTracker('Video', 'Play')}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutApp;
