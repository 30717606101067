import React from 'react';

const Title = ({ text, count }) => {
  return (
    <h2 className="title" style={{ marginBottom: count }}>
      {text}
    </h2>
  );
};

export default Title;
