import React, { useState } from 'react';
import useGAEventTracker from '../../../../analytics/useGAEventTracker';

const QuestionsAccordion = ({ title, info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const GAEventsTracker = useGAEventTracker('Questions');

  return (
    <div
      className="questions__item"
      onClick={() => {
        setIsOpen(!isOpen);
        GAEventsTracker('Open', `${title}`);
      }}>
      <div className="questions__item-top">
        <h3 className={`questions__item-title ${isOpen ? 'questions__item-title_active' : ''}`}>{title}</h3>
        <div className="questions__item-icon">
          {isOpen ? (
            <svg width="22" height="2" viewBox="0 0 22 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21 1.90735e-06H1C0.734784 1.90735e-06 0.48043 0.105359 0.292893 0.292895C0.105357 0.480432 0 0.734785 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H21C21.2652 2 21.5196 1.89464 21.7071 1.70711C21.8946 1.51957 22 1.26522 22 1C22 0.734785 21.8946 0.480432 21.7071 0.292895C21.5196 0.105359 21.2652 1.90735e-06 21 1.90735e-06Z"
                fill="#02136A"
              />
            </svg>
          ) : (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 1C12 0.734784 11.8946 0.48043 11.7071 0.292893C11.5196 0.105357 11.2652 0 11 0C10.7348 0 10.4804 0.105357 10.2929 0.292893C10.1054 0.48043 10 0.734784 10 1V10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H10V21C10 21.2652 10.1054 21.5196 10.2929 21.7071C10.4804 21.8946 10.7348 22 11 22C11.2652 22 11.5196 21.8946 11.7071 21.7071C11.8946 21.5196 12 21.2652 12 21V12H21C21.2652 12 21.5196 11.8946 21.7071 11.7071C21.8946 11.5196 22 11.2652 22 11C22 10.7348 21.8946 10.4804 21.7071 10.2929C21.5196 10.1054 21.2652 10 21 10H12V1Z"
                fill="#02136A"
              />
            </svg>
          )}
        </div>
      </div>
      <p
        className={`questions__item-info ${isOpen ? 'questions__item-info_active' : ''}`}
        dangerouslySetInnerHTML={{ __html: info }}
      />
    </div>
  );
};

export default QuestionsAccordion;
