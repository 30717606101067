import React from 'react';
import FirstScreenHome from './FirstScreenHome/FirstScreenHome';
import Terms from './Terms/Terms';
import Issue from './Issue/Issue';
import Pay from './Pay/Pay';
import Buy from './Buy/Buy';
import Questions from './Questions/Questions';
import AboutApp from './AboutApp/AboutApp';
import Repay from './Repay/Repay';
import FormBlock from './FormBlock/FormBlock';

const Home = () => {
  return (
    <>
      <FirstScreenHome />
      <Terms />
      <Issue />
      <Pay />
      <Buy />
      <Questions />
      <AboutApp />
      <Repay />
      <FormBlock />
    </>
  );
};

export default Home;
