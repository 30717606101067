import React from 'react';
import useGAEventTracker from '../../../analytics/useGAEventTracker';

const DownloadButton = ({ text, custom = false }) => {
  const GAEventsTracker = useGAEventTracker('Download App');

  return custom ? (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="downloadButton downloadButton_desktop">
        <span className="first" />
        <span className="second" />
        <span className="third" />
        <span className="fourth" />
        {text}
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="downloadButton downloadButton_mobile">
        <span className="first" />
        <span className="second" />
        <span className="third" />
        <span className="fourth" />
        {text}
      </a>
    </>
  ) : (
    <>
      <a
        href="#about"
        className="downloadButton downloadButton_desktop"
        onClick={() => GAEventsTracker('click', 'download desktop')}>
        <span className="first" />
        <span className="second" />
        <span className="third" />
        <span className="fourth" />
        {text}
      </a>
      <a
        href="https://bit.ly/3uBYxFD"
        className="downloadButton downloadButton_mobile"
        onClick={() => GAEventsTracker('click', 'download mobile')}>
        <span className="first" />
        <span className="second" />
        <span className="third" />
        <span className="fourth" />
        {text}
      </a>
    </>
  );
};

export default DownloadButton;
