import React from 'react';
import Som from '../Som/Som';

const PaymentsCard = ({ month, money }) => {
  return (
    <div className="pay__card">
      <p className="pay__card-month">{month}</p>
      <p className="pay__card-money">
        {money} <Som />
      </p>
    </div>
  );
};

export default PaymentsCard;
