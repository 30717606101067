import React from 'react';
import bish from './bish.svg';
import dordoi from './dordoi.svg';
import vesna from './vesna.svg';
import tash from './tash.svg';
import news from './new.svg';
import elekt from './elekt.svg';
import jen from './jens.svg';
import child from './child.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import { Navigation } from 'swiper/modules';

const WhereUse = () => {
  return (
    <section id="where" className="whereUse">
      <div className="containerMob">
        <h2 className="whereUse__title">Где можно пользоваться</h2>
        <p className="whereUse__subtitle">
          С помощью баланса можно оплачивать <br />
          в рассрочку товары и услуги <br />у наших партнёров:
        </p>
      </div>
      <div className="whereUse__swiper">
        <h3 className="whereUse__title-swipe">Торговые центры</h3>
        <Swiper
          loop={true}
          modules={[Navigation]}
          slidesPerView={'auto'}
          spaceBetween={8}
          navigation={true}
          className="mySwiper">
          <SwiperSlide>
            <div className="firstScreenMobile__item">
              <img src={bish} alt="Бишкек Парк" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item">
              <img src={dordoi} alt="Дордой плаза" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item">
              <img src={vesna} alt="Весна" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item">
              <img src={tash} alt="Таш - Рабат" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="whereUse__swiper whereUse__swiper2">
        <h3 className="whereUse__title-swipe whereUse__title-swipe2">Категории</h3>
        <Swiper
          loop={true}
          modules={[Navigation]}
          navigation={true}
          slidesPerView={'auto'}
          spaceBetween={8}
          className="mySwiper">
          <SwiperSlide>
            <div className="firstScreenMobile__item firstScreenMobile__item_2">
              <img src={news} alt="Новые партнеры" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item firstScreenMobile__item_2">
              <img src={elekt} alt="Электроника" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item firstScreenMobile__item_2">
              <img src={jen} alt="Женская одежда" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item firstScreenMobile__item_2">
              <img src={child} alt="Товары для детей" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default WhereUse;
