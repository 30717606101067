import React from 'react';

const TermsCard = ({ image, text }) => {
  return (
    <div className="terms__card">
      <img src={image} alt="Money Logo" />
      <p className="terms__card-text">{text}</p>
    </div>
  );
};

export default TermsCard;
