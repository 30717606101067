import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../Title/Title';
import PaymentsCard from './PaymentsCard';
import Jacket from '../../../assets/home/jacket.png';
import Sneakers from '../../../assets/home/sneakers.png';
import PriceCard from './PriceCard';

const Pay = () => {
  const { t } = useTranslation();

  return (
    <section className="pay">
      <div className="container">
        <Title text={t('block4.title')} count="50px" />
        <div className="pay__content">
          <div className="pay__table">
            <div className="pay__row">
              <div className="pay__card pay__card_2">{t('block4.payments')}</div>
              <PaymentsCard month="май" money="1550" />
              <PaymentsCard month="июнь" money="1550" />
              <PaymentsCard month="июль" money="3333" />
              <PaymentsCard month="август" money="3333" />
              <PaymentsCard month="сентябрь" money="1783" />
            </div>
            <div className="pay__row">
              <div className="pay__card pay__card_2 pay__card_flex">
                <img className="pay__card-img" src={Jacket} alt="Jacket" />
                <div className="pay__card-desc">
                  <p className="pay__card-desc-title">Куртка</p>
                  <p className="pay__card-desc-price">4650 сом</p>
                </div>
              </div>
              <PriceCard price="1550" bg="#DAF0EE" />
              <PriceCard price="1550" bg="#DAF0EE" />
              <PriceCard price="1550" bg="#DAF0EE" />
            </div>
            <div className="pay__row pay__row_end">
              <div className="pay__card_2"></div>
              <div className="pay__card pay__card_2 pay__card_flex">
                <img className="pay__card-img" src={Sneakers} alt="Sneakers" />
                <div className="pay__card-desc pay__card-desc_sneakers">
                  <p className="pay__card-desc-title">Кроссовки</p>
                  <p className="pay__card-desc-price">5350 сом</p>
                </div>
              </div>
              <PriceCard price="1783" bg="#DAF0EE" />
              <PriceCard price="1783" bg="#DAF0EE" />
              <PriceCard price="1783" bg="#DAF0EE" />
            </div>
            <div className="pay__row pay__row_rem">
              <div className="pay__card pay__card_2 ">{t('block4.remainder')}</div>
              <PriceCard price="5350" bg="#139DAF" />
              <PriceCard price="1550" bg="#139DAF" />
              <PriceCard price="4883" bg="#139DAF" />
              <PriceCard price="8217" bg="#139DAF" />
              <PriceCard price="10000" bg="#139DAF" />
            </div>
          </div>
          <p className="pay__sum">{t('block4.total')}</p>
        </div>
      </div>
    </section>
  );
};

export default Pay;
