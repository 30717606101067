import React from 'react';
import FirstScreenMobile from './FirstScreenMobile/FirstScreenMobile';
import './mobile.scss';
import WhereUse from './WhereUse/WhereUse';
import HowStart from './HowStart/HowStart';
import MobileQuestions from './MobileQuestions/MobileQuestions';

import NotFound from '../NotFound/NotFound';

const Mobile = () => {
  return (
    <>
      <div className="desktop">
        <NotFound />
      </div>
      <div className="mobile">
        <FirstScreenMobile />
        <WhereUse />
        <HowStart />
        <MobileQuestions />
      </div>
    </>
  );
};

export default Mobile;
