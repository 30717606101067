import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const docsItems = [
  {
    route: '/doc/new-loan-agreement',
    titleRu: 'Публичный кредитный договор - редакция от 04.06.2024',
    titleKy: 'Мамлекеттик насыя келишими - чыгарылыш 04.06.2024',
    id: Date.now(),
  },
  {
    route: '/doc/consent-of-the-spouse',
    titleRu: 'Согласие супруга',
    titleKy: 'Согласие супруга',
    id: Date.now(),
  },
  {
    route: '/doc/info-user',
    titleRu: 'Информация для клиента',
    titleKy: 'КАРДАР ҮЧҮН МААЛЫМАТ',
    id: Date.now(),
  },
  {
    route: '/doc/rules-promotion',
    titleRu: 'Правила проведения акции',
    titleKy: '"Досуңду алып кел" акциясын эрежеси',
    id: Date.now(),
  },
  {
    route: '/doc/rights-and-expenses',
    titleRu: 'Перечень прав, расходов (платежей) и штрафных санкций',
    titleKy: 'Укуктардын, чыгымдардын (төлөмдөрдүн) жана айып санкцияларынын тизмеги',
    id: Date.now(),
  },
  {
    route: '/doc/oferta',
    titleRu: 'Публичная оферта',
    titleKy: 'Ачык оферта',
    id: Date.now(),
  },
  {
    route: '/doc/consent-of-the-personal-data-subject',
    titleRu: 'Согласие субъекта персональных данных на сбор и обработку его персональных данных',
    titleKy: 'Жеке маалыматтар субъектинин анын жеке маалыматтарын чогултууга жана иштетүүгө макулдугу',
    id: Date.now(),
  },
  {
    route: '/doc/requistes',
    titleRu: 'Реквизиты МКК Келечек для МП',
    titleKy: 'Реквизиты МКК Келечек для МП',
    id: Date.now(),
  },
  {
    route: '/doc/privacy-policy',
    titleRu: 'Политика безопасности и конфиденциальности мобильного приложения',
    titleKy: 'Мобилдик колдонмонун коопсуздугу жана купуялык саясаты',
    id: Date.now(),
  },
  {
    route: '/doc/branches',
    titleRu: 'Филиалы',
    titleKy: 'Филиалдар',
    id: Date.now(),
  },
  {
    route: '/doc/public-offer-partner',
    titleRu: 'Публичная оферта для партнера',
    titleKy: 'Өнөктөш үчүн ачык оферта',
    id: Date.now(),
  },
];
const walletDocs = [
  {
    route: 'PersonalInfoAndSubjectWallet.html',
    routeKy: 'PersonalInfoAndSubjectWalletKy.html',
    titleRu: 'Согласие субъекта персональных данных на сбор и обработку его персональных данных',
    titleKy: 'Жеке маалыматтар субъектисинин анын жеке маалыматтарын чогултууга жана иштеп чыгууга макулдугу',
    id: Date.now(),
  },
  {
    route: 'PublicOfferWallet.html',
    routeKy: 'PublicOfferWalletKy.html',
    titleRu: 'Публичная оферта Электронного кошелька «Cash2u»',
    titleKy: 'Айкын оферта',
    id: Date.now(),
  },
  {
    route: 'PrivacyPolicyWallet.html',
    routeKy: 'PrivacyPolicyWalletKy.html',
    titleRu: 'Политика безопасности и конфиденциальности мобильного приложения',
    titleKy: 'Мобилдик тиркеменин коопсуздугу жана купуялык саясаты',
    id: Date.now(),
  },
  {
    route: 'LimitsForWalletUsers.html',
    routeKy: 'LimitsForWalletUsers.html',
    titleRu: 'Тарифы и лимиты для держателей Электронного кошелька cash2u',
    titleKy: 'Тарифы и лимиты для держателей Электронного кошелька cash2u',
    id: Date.now(),
  },
  {
    route: 'ApplicationForServiceWallet.html',
    routeKy: 'ApplicationForServiceWalletKy.html',
    titleRu: 'Заявление на обслуживание кошелька',
    titleKy: 'Cash2u электрондук капчыкты тейлөөгө арыз',
    id: Date.now(),
  },
];
const Docs = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="docs">
      <div className="container">
        <h2 className="docs__title">МКК Келечек</h2>
        <ul className="docs__menu">
          {docsItems.map(item => (
            <li className="docs__item">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="file-text"
                width="40"
                height="40"
                fill="currentColor"
                aria-hidden="true">
                <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
              </svg>
              <Link to={item.route} className="docs__link">
                {i18n.language !== 'ky' ? item.titleRu : item.titleKy}
              </Link>
            </li>
          ))}
        </ul>
        <div className="choice__item">
          <li className="docs__item">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="file-text"
              width="40"
              height="40"
              fill="currentColor"
              aria-hidden="true">
              <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
            </svg>
            <a href={'/documents/minust.pdf'} className="docs__link">
              {i18n.language !== 'ky'
                ? 'Свидетельство о регистрации - Министерство Юстиции'
                : 'Каттоо жөнүндө күбөлүк - Юстиция Министирлиги'}
            </a>
          </li>
        </div>
        <div className="choice__item">
          <li className="docs__item">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="file-text"
              width="40"
              height="40"
              fill="currentColor"
              aria-hidden="true">
              <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
            </svg>
            <a href={'/documents/nbkr-doc.pdf'} className="docs__link">
              {i18n.language !== 'ky' ? 'Свидетельство о регистрации - НБКР' : 'Каттоо жөнүндө күбөлүк - НБКР'}
            </a>
          </li>
        </div>
        <Link to="/archive">
          <div className="choice__item">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="40" height="40">
              <path d="M 2.5 2 C 1.675781 2 1 2.675781 1 3.5 L 1 12.5 C 1 13.324219 1.675781 14 2.5 14 L 13.5 14 C 14.324219 14 15 13.324219 15 12.5 L 15 5.5 C 15 4.675781 14.324219 4 13.5 4 L 6.796875 4 L 6.144531 2.789063 C 5.882813 2.300781 5.375 2 4.824219 2 Z M 2.5 3 L 4.824219 3 C 5.007813 3 5.175781 3.101563 5.265625 3.261719 L 5.664063 4 L 2 4 L 2 3.5 C 2 3.21875 2.21875 3 2.5 3 Z M 2 5 L 13.5 5 C 13.78125 5 14 5.21875 14 5.5 L 14 12.5 C 14 12.78125 13.78125 13 13.5 13 L 2.5 13 C 2.21875 13 2 12.78125 2 12.5 Z" />
            </svg>
            <p>Архивы</p>
          </div>
        </Link>

        <h2 className="docs__title">Кошелек cash2u</h2>
        <ul className="docs__menu">
          {walletDocs.map(item => (
            <li className="docs__item">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="file-text"
                width="40"
                height="40"
                fill="currentColor"
                aria-hidden="true">
                <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path>
              </svg>
              <a href={`/documents/${i18n.language !== 'ky' ? item.route : item.routeKy}`} className="docs__link">
                {i18n.language !== 'ky' ? item.titleRu : item.titleKy}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Docs;
