import React from 'react';
import Title from '../Title/Title';
import { useTranslation } from 'react-i18next';
import Terminal from './terminal.png';
import Phone from './cardPhone.png';
import Bank from './bank.png';
import BankCard from './bank-cards.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Repay = () => {
  const { t } = useTranslation();

  return (
    <section className="repay" id="repay">
      <div className="container">
        <Title text={t('block9.title')} count="40px" />
        <div className="repay__row">
          <div className="repay__card">
            <LazyLoadImage effect="blur" className="repay__card-img" src={Terminal} alt={t('block9.cards.c1.title')} />
            <h3 className="repay__card-title">{t('block9.cards.c1.title')}</h3>
            <ul className="repay__card-list">
              <li className="repay__card-item">{t('block9.cards.c1.li1')}</li>
              <li className="repay__card-item">{t('block9.cards.c1.li2')}</li>
              <li className="repay__card-item">{t('block9.cards.c1.li3')}</li>
              <li className="repay__card-item">{t('block9.cards.c1.li4')}</li>
              <li className="repay__card-item">{t('block9.cards.c1.li5')}</li>
              <li className="repay__card-item">{t('block9.cards.c1.li6')}</li>
            </ul>
          </div>
          <div className="repay__card">
            <LazyLoadImage effect="blur" className="repay__card-img" src={Phone} alt={t('block9.cards.c2.title')} />
            <h3 className="repay__card-title">{t('block9.cards.c2.title')}</h3>
            <ul className="repay__card-list">
              <li className="repay__card-item">{t('block9.cards.c2.li1')}</li>
              <li className="repay__card-item">{t('block9.cards.c2.li2')}</li>
              <li className="repay__card-item">{t('block9.cards.c2.li3')}</li>
              <li className="repay__card-item">{t('block9.cards.c2.li4')}</li>
            </ul>
          </div>
          <div className="repay__card">
            <LazyLoadImage effect="blur" className="repay__card-img" src={Bank} alt={t('block9.cards.c3.title')} />
            <h3 className="repay__card-title">{t('block9.cards.c3.title')}</h3>
            <ul className="repay__card-list">
              <li className="repay__card-item">{t('block9.cards.c3.li1')}</li>
              <li className="repay__card-item">{t('block9.cards.c3.li2')}</li>
            </ul>
          </div>
          <div className="repay__card">
            <LazyLoadImage effect="blur" className="repay__card-img" src={BankCard} alt={t('block9.cards.c4.title')} />
            <h3 className="repay__card-title">{t('block9.cards.c4.title')}</h3>
            <ul className="repay__card-list">
              <li className="repay__card-item">{t('block9.cards.c4.li1')}</li>
              <li className="repay__card-item">{t('block9.cards.c4.li2')}</li>
              <li className="repay__card-item">{t('block9.cards.c4.li3')}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Repay;
