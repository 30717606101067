import React from 'react';
import MobileQuestionAccordion from './MobileQuestionAccordion';
import smile from './mobile.svg';

const MobileQuestions = () => {
  return (
    <section className="mobileQuestions">
      <div className="containerMob">
        <h2 className="mobileQuestions__title">Частые вопросы</h2>
        <div className="mobileQuestions__content">
          <MobileQuestionAccordion
            title="Как погасить рассрочку?"
            info="Погашение Вы можете произвести через: <br/>Терминалы компаний: Pay 24, QuickPay, Оңой. <br/>Мобильные кошельки: «MegaPay», «О! Деньги», «Balance.kg». <br/>Банковские карты: Visa и Элкарт внутри приложения. <br/> Банковский перевод или пополнение наличными на расчетный счет ОсОО «МКК Келечек» в ОАО «Айыл Банк». В нашем офисе по адресу: г. Бишкек, ул. Льва Толстого 126/8."
          />
          <MobileQuestionAccordion
            title="Есть ли комиссии?"
            info="За оформление покупок в рассрочку с клиента не взымается комиссия или другие сборы"
          />
          <MobileQuestionAccordion
            title="Досрочное погашение!"
            info="Да, Вы можете досрочно погасить выставленную сумму для погашения согласно графику или погасить всю сумму задолженности по всем покупкам, внеся сумму до даты погашения через терминал, эл. кошельки, расчетный счет нашей компании в банке или в кассе нашего офиса. При своевременном погашении или досрочном погашении вся погашенная сумма вновь будет доступна для приобретения товаров и услуг."
          />
          <MobileQuestionAccordion
            title="Минимальная сумма покупки?"
            info="Минимального ограничения по приобретению товара нет. <br/>Через приложение «cash2u» Вы можете приобретать товар или услугу на любую сумму если она не превышает Ваш лимит."
          />
          <MobileQuestionAccordion
            title="Почему не одобрили рассрочку?"
            info="Возможно несколько причин: <br/>- У вас есть просроченная задолженность по кредитам (КИБ) <br/>- Вам менее 23 лет или более 60 лет. <br/>- Вы ранее уже зарегистрировались в приложении cash2u <br/>- У Вас есть непогашенная налоговая задолженность <br/>- Ваши текущие доходы не позволяют покрывать обязательства по кредиту"
          />
        </div>
        <a href="https://bit.ly/3uBYxFD" className="mobile__btn">
          <img src={smile} alt="smile" style={{ marginRight: '10px' }} />
          Скачать приложение
        </a>
      </div>
    </section>
  );
};

export default MobileQuestions;
