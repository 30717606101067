import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import Preloader from '../../Preloader';

const RedirectToApp = ({ ios, android }) => {
  const navigate = useNavigate();
  useEffect(() => {
    getMobileOperatingSystem();
  }, []);
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      window.location.replace(android);
      setTimeout(() => {
        navigate('/');
      }, 3000);
      return;
    }

    if (/android/i.test(userAgent)) {
      window.location.replace(android);
      setTimeout(() => {
        navigate('/');
      }, 3000);
      return;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.replace(ios);
      setTimeout(() => {
        navigate('/');
      }, 3000);
      return;
    }
    return navigate('/#about');
  }
  return <Preloader />;
};

export default RedirectToApp;
