import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';

import Preloader from './pages/Preloader';
import Layout from './Layout/Layout';
import Home from './pages/Home/Home';
import Docs from './pages/Docs/Docs';
import NotFound from './pages/NotFound/NotFound';
import Mobile from './pages/Mobile/Mobile';

import './app.scss';
import RedirectToApp from './pages/Mobile/RedirectToApp/RedirectToApp';
import Archive from './pages/Docs/Archive';

const Archive20_08_23PublicCreditContractKy = lazy(
  () => import('./pages/Docs/Archive/NewPublicCreditContract_20_08_23/NewPublicCreditContractKy'),
);
const Archive20_08_23PublicCreditContractRu = lazy(
  () => import('./pages/Docs/Archive/NewPublicCreditContract_20_08_23/NewPublicCreditContract'),
);
const PenaltyCareInfo = lazy(() => import('./pages/NewsDetails/PenaltyCareInfo'));
const OfflineIdentification = lazy(() => import('./pages/NewsDetails/OfflineIdentification/OfflineIdentification'));
const TelephoneScamRu = lazy(() => import('./pages/NewsDetails/TelephoneScam/TelephoneScamRu'));
const TelephoneScamKy = lazy(() => import('./pages/NewsDetails/TelephoneScam/TelephoneScamKy'));
const PenaltyNoticeChangeRu = lazy(() => import('./pages/NewsDetails/PenaltyNoticeChange/PenaltyNoticeChangeRu'));
const PenaltyNoticeChangeKy = lazy(() => import('./pages/NewsDetails/PenaltyNoticeChange/PenaltyNoticeChangeKy'));
const ReferralRedirect = lazy(() => import('./pages/ReferralRedirect/ReferralRedirect'));

const PromoBringFriend = lazy(() => import('./pages/NewsDetails/PromoBringFriend'));
const DowloadApp = lazy(() => import('./pages/DownloadApp/DownloadApp'));

const PenaltyNotice = lazy(() => import('./pages/NewsDetails/PenaltyNotice'));
const ImprovingTermsFuelCard = lazy(() => import('./pages/Docs/Doc/ImprovingTermsFuelCard/ImprovingTermsFuelCard'));
const NewSettlement = lazy(() => import('./pages/Docs/Doc/NewSettlement/NewSettlement'));

const News = lazy(() => import('./pages/Home/News/News'));
const NatBankDecreeRu = lazy(() => import('./pages/Docs/Doc/NatBankDecree/NatBankDecreeRu'));
const NatBankDecreeKy = lazy(() => import('./pages/Docs/Doc/NatBankDecree/NatBankDecreeKy'));
const NewsForPartner = lazy(() => import('./pages/Mobile/News/NewsForPartner'));
const Business = lazy(() => import('./pages/Business/Business'));
const UserInfoRu = lazy(() => import('./pages/Docs/Doc/UsersInfo/UserInfoRu'));
const FirstPointRu = lazy(() => import('./pages/Docs/Doc/FirstPoint/FirstPointRu'));
const FirstPointKG = lazy(() => import('./pages/Docs/Doc/FirstPoint/FirstPointKG'));
const Rules = lazy(() => import('./pages/Docs/Doc/Rules/Rules'));
const RulesKy = lazy(() => import('./pages/Docs/Doc/Rules/RulesKy'));

const PublicOfferRu = lazy(() => import('./pages/Docs/Doc/PublicOffer/PublicOfferRu'));
const PublicOfferKg = lazy(() => import('./pages/Docs/Doc/PublicOffer/PublicOfferKg'));
const PersonalInfoAndSubjectRu = lazy(() => import('./pages/Docs/Doc/PersonalInfoAndSubject/PersonalInfoAndSubjectRu'));
const PersonalInfoAndSubjectKg = lazy(() => import('./pages/Docs/Doc/PersonalInfoAndSubject/PersonalInfoAndSubjectKg'));
const PublicCreditContractRu = lazy(() => import('./pages/Docs/Doc/PublicCreditContract/PublicCreditContractRu'));
const NewPublicCreditContractRu = lazy(
  () => import('./pages/Docs/Doc/NewPublicCreditContract/NewPublicCreditContract'),
);
const ConsentOfTheSpouse = lazy(() => import('./pages/Docs/Doc/ConsentOfTheSpouse/ConsentOfTheSpouse'));
const NewPublicCreditContractKy = lazy(
  () => import('./pages/Docs/Doc/NewPublicCreditContract/NewPublicCreditContractKy'),
);
// const NewPublicCreditContractKy = lazy(() =>
//   import('./pages/Docs/Doc/NewPublicCreditContract/NewPublicCreditContractKy')
// );
const MobileAppRulesRu = lazy(() => import('./pages/Docs/Doc/MobileApplication/MobileAppRulesRu'));
const MobileAppRulesKg = lazy(() => import('./pages/Docs/Doc/MobileApplication/MobileAppRulesKg'));
const Requistes = lazy(() => import('./pages/Docs/Doc/Requistes/Requistes'));
const RequistesKy = lazy(() => import('./pages/Docs/Doc/Requistes/RequistesKy'));

const UserInfoKg = lazy(() => import('./pages/Docs/Doc/UsersInfo/UserInfoKg'));
// const CreditPassport = lazy(() =>
//   import('./pages/Docs/Doc/CreditPassport/CreditPassport')
// );
// const FuelCardDoc = lazy(() => import('./pages/Docs/Doc/FuelCard/fuelCard'));
const BranchesDocRu = lazy(() => import('./pages/Docs/Doc/Branches/BranchesRu'));
const BranchesDocKy = lazy(() => import('./pages/Docs/Doc/Branches/BranchesKy'));
const PublicOfferPartner = lazy(() => import('./pages/Docs/Doc/PublicOfferPartner/PublicOfferPartner'));
const PublicOfferPartnerKy = lazy(() => import('./pages/Docs/Doc/PublicOfferPartner/PublicOfferPartnerKy'));
const CareerDetail = lazy(() => import('./pages/Career/CareerDetail'));
const CareerContactCenter = lazy(() => import('./pages/Career/CareerContactCenter'));
const CareerCreditSpecialist = lazy(() => import('./pages/Career/CareerCreditSpecialist'));
const CareerInternCreditSpecialist = lazy(() => import('./pages/Career/CareerInternCreditSpecialist'));
const ChiefComplianceSpecialist = lazy(() => import('./pages/Career/ChiefComplianceSpecialist'));

const CareerList = lazy(() => import('./pages/Career/CareerList'));
const IncreasingCreditDiscipline = lazy(() => import('./pages/NewsDetails/IncreasingCreditDiscipline'));
// ! Archive
const ArchiveNewPublicCreditContractRu = lazy(
  () => import('./pages/Docs/Archive/ArchiveNewPublicCreditContract/ArchiveNewPublicCreditContract'),
);

const ArchiveNewPublicCreditContractKy = lazy(
  () => import('./pages/Docs/Archive/ArchiveNewPublicCreditContract/ArchiveNewPublicCreditContractKy'),
);
const ArchiveNewPublicCreditContractRu23 = lazy(
  () => import('./pages/Docs/Archive/PublicCreditContract_19_09_2023/PublicCreditContractRu_19_09_2023'),
);

const ArchiveNewPublicCreditContractKy23 = lazy(
  () => import('./pages/Docs/Archive/PublicCreditContract_19_09_2023/PublicCreditContractKg_19_09_2023'),
);
const ArchiveFirstPointRu = lazy(() => import('./pages/Docs/Archive/ArchiveFirstPoint/FirstPointRu'));
const ArchiveFirstPointKG = lazy(() => import('./pages/Docs/Archive/ArchiveFirstPoint/FirstPointKG'));

const FuelCard = lazy(() => import('./pages/FuelCard/FuelCard'));
const ZeroZeroSix = lazy(() => import('./pages/006/006'));
const ZeroZeroThree = lazy(() => import('./pages/003/003'));
const InstallmentPlanMsb = lazy(() => import('./pages/InstallmentPlanMSB/InstallmentPlanMsb'));
const Registration = lazy(() => import('./pages/Registration/Registration'));
const Repayment = lazy(() => import('./pages/Repayment/Repayment'));
const RepaymentMega = lazy(() => import('./pages/Repayment/RepaymentMega'));
const RepaymentO = lazy(() => import('./pages/Repayment/RepaymentO'));
const RepaymentPay24 = lazy(() => import('./pages/Repayment/RepaymentPay24'));
const RepaymentQuickPay = lazy(() => import('./pages/Repayment/RepaymentQuickPay'));
const RepaymentBalance = lazy(() => import('./pages/Repayment/RepaymentBalance'));
const RepaymentMegapay = lazy(() => import('./pages/Repayment/RepaymentMegapay'));
const RepaymentOMoney = lazy(() => import('./pages/Repayment/RepaymentOMoney'));
const RepaymentElsom = lazy(() => import('./pages/Repayment/RepaymentElsom'));
const RepaymentElcart = lazy(() => import('./pages/Repayment/RepaymentElcart'));
const RepaymentCash2u = lazy(() => import('./pages/Repayment/RepaymentCash2u'));

const docRoutes = [
  {
    path: 'telephone-scam',
    componentRu: TelephoneScamRu,
    componentKy: TelephoneScamKy,
    id: new Date().getMilliseconds(),
  },
  {
    path: 'offline-identification',
    componentRu: OfflineIdentification,
    componentKy: OfflineIdentification,
    id: 332221222231121,
  },
  {
    path: 'rights-and-expenses',
    componentRu: FirstPointRu,
    componentKy: FirstPointKG,
    id: 2,
  },
  {
    path: 'rules-promotion',
    componentRu: Rules,
    componentKy: RulesKy,
    id: 3232,
  },
  {
    path: 'oferta',
    componentRu: PublicOfferRu,
    componentKy: PublicOfferKg,
    id: 12,
  },
  {
    path: 'consent-of-the-personal-data-subject',
    componentRu: PersonalInfoAndSubjectRu,
    componentKy: PersonalInfoAndSubjectKg,
    id: 556,
  },

  {
    path: 'privacy-policy',
    componentRu: MobileAppRulesRu,
    componentKy: MobileAppRulesKg,
    id: 6,
  },
  {
    path: 'requistes',
    componentRu: Requistes,
    componentKy: RequistesKy,
    id: 65656,
  },
  {
    path: 'new-loan-agreement',
    componentRu: PublicCreditContractRu,
    componentKy: PublicCreditContractRu,
    id: 1565456,
  },
  {
    path: 'consent-of-the-spouse',
    componentRu: ConsentOfTheSpouse,
    componentKy: ConsentOfTheSpouse,
    id: new Date().getMilliseconds(),
  },
  {
    path: 'improving-fuel-card-terms',
    componentRu: ImprovingTermsFuelCard,
    componentKy: ImprovingTermsFuelCard,
    id: 123332565456,
  },

  {
    path: 'info-user',
    componentRu: UserInfoRu,
    componentKy: UserInfoKg,
    id: 323,
  },
  {
    path: 'branches',
    componentRu: BranchesDocRu,
    componentKy: BranchesDocKy,
    id: 27657,
  },
  {
    path: 'public-offer-partner',
    componentRu: PublicOfferPartner,
    componentKy: PublicOfferPartnerKy,
    id: 2324342333,
  },
  {
    path: 'nat-bank-decree',
    componentRu: NatBankDecreeRu,
    componentKy: NatBankDecreeKy,
    id: 3432,
  },
  {
    path: 'new-settlement-method',
    componentRu: NewSettlement,
    componentKy: NewSettlement,
    id: 321222432,
  },
  {
    path: 'extension-promo-bring-friend',
    componentRu: PromoBringFriend,
    componentKy: PromoBringFriend,
    id: 1123321,
  },
  {
    path: 'increasing-credit-discipline',
    componentRu: IncreasingCreditDiscipline,
    componentKy: IncreasingCreditDiscipline,
    id: 332211,
  },
  {
    path: 'penalty-notice',
    componentRu: PenaltyNotice,
    componentKy: PenaltyNotice,
    id: 33222231111,
  },
  {
    path: 'penalty-care-info',
    componentRu: PenaltyCareInfo,
    componentKy: PenaltyCareInfo,
    id: 332221222231111,
  },
  {
    path: 'penalty-care-info-change',
    componentRu: PenaltyNoticeChangeRu,
    componentKy: PenaltyNoticeChangeKy,
    id: 3322212222311114,
  },
];
const archiveDocs = [
  {
    path: 'loan-agreement-01.06.2022',
    componentRu: ArchiveNewPublicCreditContractRu,
    componentKy: ArchiveNewPublicCreditContractKy,
    id: 15622215456,
  },
  {
    path: 'rights-and-expenses',
    componentRu: ArchiveFirstPointRu,
    componentKy: ArchiveFirstPointKG,
    id: 2121212242,
  },
  {
    path: 'loan-agreement',
    componentRu: PublicCreditContractRu,
    componentKy: PublicCreditContractRu,
    id: 4344,
  },
  {
    path: 'loan-agreement-20.08.2023',
    componentRu: Archive20_08_23PublicCreditContractRu,
    componentKy: Archive20_08_23PublicCreditContractKy,
    id: 4344,
  },
  {
    path: 'loan-agreement-19.09.2023',
    componentRu: ArchiveNewPublicCreditContractRu23,
    componentKy: ArchiveNewPublicCreditContractKy23,
    id: 15622215456,
  },
];
const fuelCard = [
  {
    path: '',
    componentRu: FuelCard,
    componentKy: FuelCard,
    id: new Date().getMilliseconds(),
  },
];
const zeroZeroThree = [
  {
    path: '',
    componentRu: ZeroZeroThree,
    componentKy: ZeroZeroThree,
    id: new Date().getMilliseconds(),
  },
];
const installmentPlanMsb = [
  {
    path: '',
    componentRu: InstallmentPlanMsb,
    componentKy: InstallmentPlanMsb,
    id: new Date().getMilliseconds(),
  },
];
const zeroZeroSix = [
  {
    path: '',
    componentRu: ZeroZeroSix,
    componentKy: ZeroZeroSix,
    id: new Date().getFullYear(),
  },
];
const registration = [
  {
    path: '',
    componentRu: Registration,
    componentKy: Registration,
    id: new Date().getMilliseconds(),
  },
];
const repayment = [
  {
    path: '',
    componentRu: Repayment,
    componentKy: Repayment,
    id: new Date().getMilliseconds(),
  },
];
const repaymentMega = [
  {
    path: '',
    componentRu: RepaymentMega,
    componentKy: RepaymentMega,
    id: new Date().getMilliseconds(),
  },
];
const repaymentO = [
  {
    path: '',
    componentRu: RepaymentO,
    componentKy: RepaymentO,
    id: new Date().getMilliseconds(),
  },
];
const repaymentPay24 = [
  {
    path: '',
    componentRu: RepaymentPay24,
    componentKy: RepaymentPay24,
    id: new Date().getMilliseconds(),
  },
];
const repaymentQuickPay = [
  {
    path: '',
    componentRu: RepaymentQuickPay,
    componentKy: RepaymentQuickPay,
    id: new Date().getMilliseconds(),
  },
];
const repaymentBalance = [
  {
    path: '',
    componentRu: RepaymentBalance,
    componentKy: RepaymentBalance,
    id: new Date().getMilliseconds(),
  },
];
const repaymentMegapay = [
  {
    path: '',
    componentRu: RepaymentMegapay,
    componentKy: RepaymentMegapay,
    id: new Date().getMilliseconds(),
  },
];
const repaymentOMoney = [
  {
    path: '',
    componentRu: RepaymentOMoney,
    componentKy: RepaymentOMoney,
    id: new Date().getMilliseconds(),
  },
];
const repaymentElsom = [
  {
    path: '',
    componentRu: RepaymentElsom,
    componentKy: RepaymentElsom,
    id: new Date().getMilliseconds(),
  },
];
const repaymentElcart = [
  {
    path: '',
    componentRu: RepaymentElcart,
    componentKy: RepaymentElcart,
    id: new Date().getMilliseconds(),
  },
];
const repaymentCash2u = [
  {
    path: '',
    componentRu: RepaymentCash2u,
    componentKy: RepaymentCash2u,
    id: new Date().getMilliseconds(),
  },
];

function App() {
  const { i18n } = useTranslation();
  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="business" element={<Business />} />
          <Route path="docs" element={<Docs />} />
          <Route path="all-news" element={<News />} />
          <Route path="news/:id" element={<NewsForPartner />} />
          <Route path="archive" element={<Archive />} />
          <Route path="career" element={<CareerList />} />

          <Route
            path="referral"
            element={
              <ReferralRedirect
                android={'https://play.google.com/store/apps/details?id=kg.cash2u.client&referrer=refererid='}
                ios={'https://apps.apple.com/kg/app/cash2u/id1601611311?utm_source=GA_site'}
                paramName={'code'}
              />
            }
          />
          <Route
            path="category"
            element={
              <ReferralRedirect
                android={'https://play.google.com/store/apps/details?id=kg.cash2u.client&referrer=categoryid='}
                ios={'https://apps.apple.com/kg/app/cash2u/id1601611311?utm_source=GA_site'}
                paramName={'categoryId'}
              />
            }
          />
          <Route path="career/customer-service-department" element={<CareerDetail />} />
          <Route path="career/contact-center-specialist" element={<CareerContactCenter />} />
          <Route path="career/credit-specialist" element={<CareerCreditSpecialist />} />
          <Route path="career/intern-credit-specialist" element={<CareerInternCreditSpecialist />} />
          <Route path="career/chief-compliance-specialist" element={<ChiefComplianceSpecialist />} />

          <Route path="doc">
            {docRoutes.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={
                  <div className="docs">
                    <div className="container">
                      {i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}
                    </div>
                  </div>
                }
              />
            ))}
          </Route>

          <Route path=":lng/doc">
            {docRoutes.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={
                  <div className="docs">
                    <div className="container">
                      {i18n.language === 'ru' ? <item.componentRu /> : <item.componentKy />}
                    </div>
                  </div>
                }
              />
            ))}
          </Route>
          <Route path="archive">
            {archiveDocs.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={
                  <div className="docs">
                    <div className="container">
                      {i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}
                    </div>
                  </div>
                }
              />
            ))}
          </Route>
          <Route path="fuel-card">
            {fuelCard.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="006">
            {zeroZeroSix.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="003">
            {zeroZeroThree.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="msb">
            {installmentPlanMsb.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="registration">
            {registration.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment">
            {repayment.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/mega">
            {repaymentMega.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/o">
            {repaymentO.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/pay24">
            {repaymentPay24.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/quickpay">
            {repaymentQuickPay.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/balance">
            {repaymentBalance.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/megapay">
            {repaymentMegapay.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/o-money">
            {repaymentOMoney.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/elcart">
            {repaymentElcart.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/elsom">
            {repaymentElsom.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route path="repayment/cash2u">
            {repaymentCash2u.map(item => (
              <Route
                key={item.id}
                path={item.path}
                element={<>{i18n.language === 'ky' ? <item.componentKy /> : <item.componentRu />}</>}
              />
            ))}
          </Route>
          <Route
            path="gotoapp"
            element={<RedirectToApp ios={'https://apple.co/3gOrOoO'} android={'https://bit.ly/3sDPx0t'} />}
          />
          <Route
            path="stickers"
            element={<RedirectToApp ios={'https://apple.co/3gOrOoO'} android={'https://bit.ly/3sDPx0t'} />}
          />
          <Route
            path="b-cad"
            element={<RedirectToApp ios={'https://apple.co/3gOrOoO'} android={'https://bit.ly/3sDPx0t'} />}
          />
          <Route
            path="businessapp"
            element={
              <RedirectToApp
                ios={'https://apps.apple.com/us/app/cash2u-business/id1611117625'}
                android={'https://play.google.com/store/apps/details?id=kg.cash2u.business'}
              />
            }
          />
          <Route path="download-app" element={<DowloadApp />} />
          <Route path="mobile" element={<Mobile />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
