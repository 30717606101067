import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import cards from './cards.svg';
import offer1 from './offers.svg';
import offer2 from './offers2.svg';
import offer3 from './offers3.svg';
import bg from './bg.png';
import './firstScreenMobile.scss';
import 'swiper/css';

const FirstScreenMobile = () => {
  return (
    <section className="firstScreenMobile">
      <div className="containerMob">
        <div className="firstScreenMobile__block">
          <h2 className="firstScreenMobile__title">
            <svg width="143" height="42" viewBox="0 0 143 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 22.1224C0 29.5341 5.30651 34.9412 12.5427 34.9412C18.621 34.9412 23.2521 31.1153 24.1205 26.0259L18.0904 24.9882C17.4632 27.5576 15.5336 29.2376 12.4944 29.2376C8.8281 29.2376 6.36781 26.52 6.36781 22.1224C6.36781 17.6753 8.97282 14.9576 12.5909 14.9576C15.2924 14.9576 17.222 16.4894 17.8492 19.1576L24.024 18.0212C22.8662 12.7341 18.5245 9.17647 12.6391 9.17647C5.35475 9.17647 0 14.6612 0 22.1224Z"
                fill="white"
              />
              <path
                d="M42.1971 34.9412H47.6001V19.5529C47.6001 12.6353 43.4031 9.17647 36.987 9.17647C30.9087 9.17647 27.0494 12.4376 25.8434 17.1318L31.6805 18.2188C32.3559 15.6988 34.1408 14.4635 36.8906 14.4635C39.3508 14.4635 40.991 15.5012 41.5217 17.9718L35.3468 19.4047C30.2815 20.5906 26.0363 22.5671 26.0363 27.6565C26.0363 32.4 29.5097 34.9412 33.8514 34.9412C37.2282 34.9412 39.7988 33.8824 41.7629 31.0659L42.1971 34.9412ZM32.2594 27.2118C32.2594 25.3835 33.7066 24.6424 35.7328 24.1482L41.6664 22.6659V23.2094C41.6664 27.7059 38.386 29.8306 35.1539 29.8306C33.4172 29.8306 32.2594 28.9412 32.2594 27.2118Z"
                fill="white"
              />
              <path
                d="M61.018 34.9412C67.9164 34.9412 71.2933 31.4612 71.2933 26.7671C71.2933 20.9859 66.228 20.0965 62.224 19.4541C59.426 19.0094 57.2552 18.6141 57.2552 16.7859C57.2552 15.3035 58.7024 14.2659 61.0662 14.2659C63.2371 14.2659 64.6843 15.2047 65.1667 17.3294L71.2451 16.0447C69.8461 11.6471 66.5657 9.17647 61.0662 9.17647C55.2291 9.17647 50.9839 12.4871 50.9839 17.0824C50.9839 22.7647 55.5185 23.8518 59.4743 24.4941C62.3687 25.0376 64.9737 25.2847 64.9737 27.1624C64.9737 28.8918 63.4783 29.88 61.018 29.88C58.5577 29.88 56.7728 28.8424 56.2904 26.2729L50.019 27.5576C51.3698 32.8447 55.615 34.9412 61.018 34.9412Z"
                fill="white"
              />
              <path
                d="M73.8276 34.9412H79.9542V20.6541C79.9542 16.5035 82.7522 15.12 85.3089 15.12C88.1552 15.12 90.1813 16.9976 90.1813 20.6541V34.9412H96.3079V19.6659C96.3079 12.8471 91.9662 9.17647 86.8044 9.17647C83.9582 9.17647 81.6909 10.4259 79.9542 12.1059V0H73.8276V34.9412Z"
                fill="white"
              />
              <path
                d="M118.88 29.5384H108.885C116.428 24.027 118.497 21 118.497 17.3482C118.497 12.7896 115.16 9.17647 109.15 9.17647C104.041 9.17647 99.9083 11.6753 98.894 16.4703L105.355 17.6859C105.656 15.7612 106.971 14.5793 108.999 14.5793C110.915 14.5793 112.155 15.6936 112.155 17.4833C112.155 19.7647 110.437 22.0882 102.912 27.8118L98.894 30.4163V34.9412H118.88V29.5384Z"
                fill="white"
              />
              <path d="M24.155 37.4118V42H0.0344913L0.0344915 37.4118H24.155Z" fill="white" />
              <path
                d="M127.116 9.17647H120.947V24.9076C120.947 31.6134 125.173 34.9412 130.176 34.9412C133.479 34.9412 135.568 33.8824 137.171 31.9664L137.462 34.9412H143V9.17647H136.831V23.6975C136.831 27.6807 134.257 29.042 131.876 29.042C128.816 29.042 127.116 27.4286 127.116 24.3025V9.17647Z"
                fill="white"
              />
              <path
                d="M138.426 1.29706H140.077C140.423 1.29706 140.706 1.37617 140.925 1.5344C141.144 1.68933 141.253 1.93657 141.253 2.2761C141.253 2.48707 141.202 2.66837 141.101 2.82001C141 2.96835 140.858 3.07548 140.675 3.14141C140.76 3.16119 140.833 3.19086 140.895 3.23041C140.957 3.26997 141.005 3.31282 141.037 3.35897C141.073 3.40513 141.103 3.46446 141.126 3.53698C141.148 3.6095 141.163 3.67378 141.17 3.72982C141.18 3.78257 141.188 3.85344 141.194 3.94244C141.197 3.96552 141.201 4.01002 141.204 4.07595C141.211 4.14188 141.214 4.18638 141.214 4.20945C141.217 4.22923 141.22 4.26549 141.224 4.31824C141.23 4.37098 141.235 4.40889 141.238 4.43196C141.242 4.45174 141.247 4.48306 141.253 4.52591C141.263 4.56547 141.271 4.59678 141.278 4.61986C141.284 4.63964 141.292 4.66436 141.302 4.69403C141.312 4.7204 141.323 4.74348 141.336 4.76325C141.349 4.78303 141.364 4.80116 141.38 4.81765H140.724C140.714 4.80446 140.704 4.78963 140.694 4.77314C140.688 4.75337 140.681 4.73359 140.675 4.71381C140.668 4.69073 140.663 4.67095 140.66 4.65447C140.657 4.63469 140.652 4.60997 140.645 4.5803C140.642 4.54734 140.639 4.52097 140.636 4.50119C140.636 4.48141 140.634 4.45174 140.631 4.41218C140.627 4.37263 140.624 4.34296 140.621 4.32318C140.621 4.3034 140.619 4.26879 140.616 4.21934C140.613 4.1699 140.611 4.13528 140.611 4.11551C140.595 3.82872 140.534 3.63258 140.43 3.52709C140.329 3.41831 140.134 3.36392 139.847 3.36392H139.033V4.81765H138.426V1.29706ZM139.033 1.80141V2.90407H139.842C139.959 2.90407 140.059 2.89747 140.141 2.88429C140.222 2.8711 140.304 2.84803 140.386 2.81506C140.471 2.7788 140.534 2.72112 140.577 2.642C140.623 2.56289 140.645 2.46235 140.645 2.34038C140.645 2.2283 140.626 2.136 140.587 2.06348C140.547 1.98766 140.49 1.93162 140.415 1.89536C140.343 1.8591 140.268 1.83438 140.19 1.82119C140.115 1.808 140.025 1.80141 139.92 1.80141H139.033Z"
                fill="white"
              />
              <path
                d="M139.813 5.68235C141.211 5.68235 142.345 4.52093 142.345 3.08824C142.345 1.65554 141.211 0.494118 139.813 0.494118C138.414 0.494118 137.28 1.65554 137.28 3.08824C137.28 4.52093 138.414 5.68235 139.813 5.68235ZM139.813 6.17647C141.478 6.17647 142.828 4.79382 142.828 3.08824C142.828 1.38265 141.478 0 139.813 0C138.147 0 136.798 1.38265 136.798 3.08824C136.798 4.79382 138.147 6.17647 139.813 6.17647Z"
                fill="white"
              />
            </svg>
          </h2>
          <p className="firstScreenMobile__subtitle">
            самый удобный <br />
            способ оплачивать <br />
            покупки частями
          </p>
          <img className="firstScreenMobile__img" src={cards} alt="cards" />
          <a className="mobile__btn" href={'#where'}>
            Как это работает?
          </a>
          <img src={bg} alt="bg" className="firstScreenMobile__block-bg" />
        </div>
        <h2 className="firstScreenMobile__title2">Предложения и акции</h2>
        <p className="firstScreenMobile__subtitle2">
          Горячие предложения и акции <br />
          от проверенных партнёров cash2u
        </p>
      </div>
      <div className="swiper__cont_2">
        <Swiper loop={true} slidesPerView={'auto'} spaceBetween={8} className="mySwiper">
          <SwiperSlide>
            <div className="firstScreenMobile__item">
              <img src={offer1} alt="offer 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item">
              <img src={offer2} alt="offer 2" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="firstScreenMobile__item">
              <img src={offer3} alt="offer 3" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default FirstScreenMobile;
