import React from 'react';
import Title from '../Title/Title';
import { useTranslation } from 'react-i18next';
import QuestionsAccordion from './QuestionsAccordion/QuestionsAccordion';

const Questions = () => {
  const { t } = useTranslation();

  return (
    <section className="questions" id="questions">
      <div className="container">
        <Title text={t('block7.title')} count="64px" />
        <div className="questions__content">
          <div className="questions__col">
            <QuestionsAccordion title={t('block7.questions.q4.title')} info={t('block7.questions.q4.answers')} />
            <QuestionsAccordion title={t('block7.questions.q7.title')} info={t('block7.questions.q7.answers')} />
            <QuestionsAccordion title={t('block7.questions.q6.title')} info={t('block7.questions.q6.answers')} />
          </div>
          <div className="questions__col">
            <QuestionsAccordion title={t('block7.questions.q5.title')} info={t('block7.questions.q5.answers')} />
            <QuestionsAccordion title={t('block7.questions.q2.title')} info={t('block7.questions.q2.answers')} />
            <QuestionsAccordion title={t('block7.questions.q3.title')} info={t('block7.questions.q3.answers')} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;
