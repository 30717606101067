import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Title from '../Title/Title';
import BuySwiper from './BuySwiper/BuySwiper';
import useGAEventTracker from '../../../analytics/useGAEventTracker';

import Instagram from '../../../assets/partner-socials/instagram.png';
import Telegram from '../../../assets/partner-socials/telegram.png';
import Facebook from '../../../assets/partner-socials/facebook.png';
import Tiktok from '../../../assets/partner-socials/tiktok.png';
import Whatsapp from '../../../assets/partner-socials/whatsapp.png';

import 'react-lazy-load-image-component/src/effects/blur.css';

const socialMedias = [
  {
    type: 'Instagram',
    img: Instagram,
  },
  {
    type: 'Telegram',
    img: Telegram,
  },
  {
    type: 'Facebook',
    img: Facebook,
  },
  {
    type: 'Tiktok',
    img: Tiktok,
  },
  {
    type: 'Whatsapp',
    img: Whatsapp,
  },
];
const Buy = () => {
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [total, setTotal] = useState(0);
  const [category, setCategory] = useState({ value: '', id: null });
  const [limit, setLimit] = useState(10);
  const { t, i18n } = useTranslation();

  const GAEventsTracker = useGAEventTracker('categories');

  useEffect(() => {
    axios(`${process.env.REACT_APP_URL}/api/v1/PartnerCategories/Search?&pageSize=100`).then(({ data }) =>
      setCategories(
        data.items
          .filter(item => item.partnersCount > 0)
          .map(item => {
            return {
              value: item.nameLocalizations.filter(lang => lang.cultureCode === i18n.language)[0].value,
              id: item.id,
            };
          })
      )
    );
  }, []);

  useEffect(() => {
    axios(
      `${process.env.REACT_APP_URL}/api/v1/Partners/Search?${
        category.value.length ? `&categories=` + category.id : ''
      }&pageSize=${limit}`
    ).then(({ data }) => {
      setImages(
        data.items.map((el, i) => {
          return {
            id: el.id,
            img: el.logoImageUrl,
            title: el.name,
            contacts: el.contacts,
          };
        })
      );
      setTotal(data.totalItemCount);
    });
  }, [limit, category]);

  const getContactImages = type => {
    if (
      type === 'Instagram' ||
      type === 'Tiktok' ||
      type === 'Telegram' ||
      type === 'Whatsapp' ||
      type === 'Facebook'
    ) {
      let sm = socialMedias.find(item => item.type === type);
      if (sm) {
        return sm.img;
      }
    } else {
      return null;
    }
  };

  return (
    <section className="buy" id="buy">
      <div className="container">
        <Title text={t('block5.title')} count="40px" />
        <BuySwiper
          setLimit={setLimit}
          setCategory={setCategory}
          category={category}
          setCategories={setCategories}
          categories={categories}
        />
        {
          <div className="buy__row">
            {images.map(store => (
              <div key={store.id} className="buy__card">
                <LazyLoadImage
                  effect="blur"
                  title={store.title}
                  className="buy__card-img"
                  src={store.img}
                  alt={store.title}
                />
                {store.contacts.length > 0 ? (
                  <div className="buy__card__hide-social-m">
                    {/* eslint-disable-next-line array-callback-return */}
                    {store.contacts.map(item => {
                      if (
                        item.type === 'Instagram' ||
                        item.type === 'Tiktok' ||
                        item.type === 'Telegram' ||
                        item.type === 'Whatsapp' ||
                        item.type === 'Facebook'
                      ) {
                        return (
                          <a key={item.value} rel="noreferrer" target="_blank" href={item.value}>
                            <LazyLoadImage effect="blur" src={getContactImages(item.type)} alt={item.type} />
                          </a>
                        );
                      }
                    })}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        }
        {limit > 10 && limit >= total ? (
          <div className="buy__more">
            <a
              href="#buy"
              className="buy__more-btn"
              onClick={() => {
                setLimit(10);
                GAEventsTracker('hide ', 'limit=10');
              }}>
              {t('block5.button2')}
            </a>
          </div>
        ) : total > 10 ? (
          <div className="buy__more">
            <button
              className="buy__more-btn"
              onClick={() => {
                setLimit(limit + 5);
                GAEventsTracker('showMore', 'limit+5');
              }}>
              {t('block5.button')}
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default Buy;
