import React from 'react';
import { useTranslation } from 'react-i18next';
// import First from '../../../assets/home/1.png'
// import Second from '../../../assets/home/2.png'
// import Third from '../../../assets/home/3.png'
import Title from '../Title/Title';

const Issue = () => {
  const { t } = useTranslation();

  return (
    <section className="issue" id="issue">
      <div className="container">
        <Title text={t('block3.title')} count="100px" />
        <div className="issue__row">
          <div className="issue__card">
            <div className="issue__card-img">
              <h3 className="issue__card-num">1</h3>
            </div>
            <div>
              <h3 className="issue__card-title">{t('block3.subtitle1')}</h3>
              <p className="issue__card-text">{t('block3.card1')}</p>
            </div>
          </div>
          <div className="issue__card">
            <div className="issue__card-img">
              <h3 className="issue__card-num">2</h3>
            </div>
            <div>
              <h3 className="issue__card-title">{t('block3.subtitle2')}</h3>
              <p className="issue__card-text">{t('block3.card2')}</p>
            </div>
          </div>
          <div className="issue__card">
            <div className="issue__card-img">
              <h3 className="issue__card-num">3</h3>
            </div>
            <div>
              <h3 className="issue__card-title">{t('block3.subtitle3')}</h3>
              <p className="issue__card-text">{t('block3.card3')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Issue;
