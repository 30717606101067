import { useTranslation } from 'react-i18next';
import useGAEventTracker from '../../../analytics/useGAEventTracker';

const SwitchLang = ({ isBurger }) => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  const changesLanguage = lang => {
    i18n.changeLanguage(lang);
  };

  const GAEventsTracker = useGAEventTracker('lang');

  return (
    <div className="switchLangs">
      <button
        className={`switchLangBtn ${i18n.language === 'ru' ? 'active' : ''}`}
        onClick={() => {
          changesLanguage('ru');
          GAEventsTracker('switch', 'ru');
        }}>
        RU
      </button>
      <button
        className={`switchLangBtn ${i18n.language === 'ky' ? 'active' : ''}`}
        onClick={() => {
          changesLanguage('ky');
          GAEventsTracker('switch', 'kg');
        }}>
        KG
      </button>
    </div>
  );
};

export default SwitchLang;
