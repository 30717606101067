import React from 'react';
import banner from './banner.svg';
import start from './start.svg';

const HowStart = () => {
  return (
    <section className="howStart">
      <div className="containerMob">
        <div className="howStart__block1">
          <img className="howStart__img" src={start} alt="start" />
          <a href="https://bit.ly/3uBYxFD" className="mobile__btn howStart__btn">
            Скачать
          </a>
        </div>
        <h2 className="howStart__title">Бонусы</h2>
        <p className="howStart__subtitle">
          После одобрения вашей заявки на <br />
          получение денег, вы автоматически <br />
          получаете доступ к бонусной программе <br />
          от cash2u
        </p>
        <img className="howStart__img" src={banner} alt="banner" />
      </div>
    </section>
  );
};

export default HowStart;
