import React, { useEffect } from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReactGa from 'react-ga';

ReactGa.initialize('AW-10851252054');

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, [location.pathname]);
  return (
    <>
      {location.pathname !== '/mobile' && location.pathname?.split('/')[1] !== 'news' ? <Header /> : null}
      <main>
        <Outlet />
      </main>

      {location.pathname === '/' ||
      location.pathname === '/business' ||
      location.pathname.includes('offline-identification') ? (
        <Footer />
      ) : (
        ''
      )}
    </>
  );
};

export default Layout;
